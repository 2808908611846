import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
          <path d="M4499 7639 c-10 -6 -6 -14 15 -30 63 -50 104 -119 131 -220 45 -168
-5 -501 -142 -949 -36 -119 -44 -145 -111 -365 -145 -472 -203 -721 -224 -957
-29 -338 58 -656 218 -797 51 -44 86 -63 141 -74 48 -9 49 8 3 54 -112 113
-153 315 -115 559 29 183 88 422 170 680 242 772 301 1017 312 1304 17 405
-137 757 -349 796 -20 4 -42 3 -49 -1z"/>
<path d="M6609 7633 c-300 -3 -359 -6 -369 -18 -10 -12 4 -64 74 -280 48 -145
91 -267 96 -270 5 -3 193 -6 417 -5 374 0 413 -2 471 -19 155 -47 269 -161
350 -346 44 -102 59 -152 87 -288 32 -155 44 -416 26 -592 -36 -353 -173 -639
-354 -739 -109 -60 -154 -67 -402 -64 l-220 3 -5 635 c-3 349 -9 641 -13 648
-7 10 -47 13 -165 11 -85 0 -161 -3 -169 -6 -11 -4 -13 -170 -13 -933 0 -821
2 -929 15 -940 13 -10 101 -12 430 -6 354 6 425 10 491 26 365 89 627 470 734
1067 39 218 51 549 30 793 -17 188 -68 441 -115 575 -120 337 -269 543 -479
659 -121 67 -263 97 -446 93 -63 -1 -275 -3 -471 -4z"/>
<path d="M5485 7603 c-97 -20 -204 -67 -278 -120 -74 -54 -192 -176 -208 -216
-14 -34 -15 -29 60 -247 27 -80 58 -187 67 -238 10 -51 22 -91 27 -89 5 1 19
21 31 42 78 145 184 244 304 284 58 20 91 22 410 27 191 3 357 7 370 10 12 2
22 11 22 18 0 15 -118 385 -154 481 l-22 60 -279 2 c-217 1 -296 -2 -350 -14z"/>
<path d="M4153 7603 c-91 -4 -93 -4 -99 -30 -4 -14 -5 -451 -2 -970 3 -519 2
-945 -2 -948 -4 -2 -38 60 -75 138 -38 78 -109 225 -158 326 -99 206 -179 372
-272 566 -34 72 -91 191 -128 265 -185 383 -267 553 -267 556 0 3 -11 22 -25
44 l-26 41 -137 -3 -137 -3 -3 -1430 c-2 -786 0 -1503 3 -1592 l7 -163 174 0
174 0 6 27 c4 14 4 483 0 1042 -4 656 -3 1021 3 1031 8 11 14 5 29 -30 11 -25
50 -108 87 -185 37 -77 100 -210 140 -295 40 -85 105 -221 144 -303 39 -81 71
-149 71 -151 0 -2 40 -86 89 -187 48 -101 102 -213 118 -249 78 -166 120 -255
180 -380 36 -74 74 -154 85 -178 11 -23 23 -41 25 -38 3 2 -5 40 -17 83 -64
231 -65 511 -5 825 40 210 71 323 217 800 l69 227 -2 551 c-1 303 -5 566 -8
583 l-7 32 -79 1 c-44 0 -122 -1 -172 -3z"/>
<path d="M5027 6724 c-4 -4 -7 -35 -7 -69 0 -71 -23 -254 -46 -370 -38 -191
-88 -363 -231 -795 -152 -461 -192 -638 -180 -810 7 -94 23 -149 68 -227 36
-63 55 -79 45 -37 -39 166 -30 354 29 583 42 161 61 215 75 206 6 -3 10 -10
10 -15 0 -39 137 -337 182 -395 151 -197 212 -251 363 -321 129 -60 195 -66
608 -58 l357 7 6 24 c4 13 2 434 -2 936 l-9 912 -363 3 -363 2 -16 -37 c-38
-91 -166 -472 -171 -507 l-4 -38 278 3 c154 2 285 0 292 -4 9 -6 12 -89 12
-357 0 -192 -4 -351 -8 -354 -21 -13 -323 -18 -388 -6 -190 34 -320 152 -417
380 -95 223 -135 541 -102 804 25 199 13 571 -18 540z"/>
<path d="M7392 4179 c-12 -4 -27 -21 -33 -36 -14 -36 -5 -129 14 -147 33 -34
107 -27 107 9 0 17 -26 20 -35 5 -3 -5 -14 -10 -24 -10 -41 0 -53 112 -17 149
13 13 19 13 41 1 17 -9 29 -10 36 -3 23 23 -47 49 -89 32z"/>
<path d="M7846 4169 c-19 -15 -24 -25 -17 -32 7 -7 17 -5 31 8 16 14 22 15 35
5 23 -19 19 -33 -30 -104 -25 -37 -45 -69 -45 -72 0 -11 114 1 118 14 2 7 -5
12 -17 12 -32 0 -35 19 -7 60 28 42 34 91 14 108 -28 22 -55 22 -82 1z"/>
<path d="M8005 4166 c-14 -14 -25 -31 -26 -38 0 -7 -2 -29 -4 -48 -4 -45 21
-99 51 -106 13 -4 35 1 53 12 26 15 31 25 31 55 0 46 -18 61 -67 58 -33 -3
-38 -1 -35 16 6 36 28 50 56 37 27 -12 44 -1 23 16 -30 24 -56 23 -82 -2z m63
-98 c17 -17 15 -43 -4 -59 -31 -26 -70 25 -44 56 15 18 32 19 48 3z"/>
<path d="M8193 4179 c-31 -11 -43 -40 -43 -104 0 -46 4 -60 25 -80 29 -30 54
-32 83 -6 26 22 30 82 7 101 -8 7 -31 10 -50 8 -31 -3 -35 -1 -35 18 0 32 22
45 61 37 30 -6 32 -5 21 9 -17 21 -43 27 -69 17z m45 -111 c30 -30 0 -78 -37
-58 -32 17 -21 70 14 70 6 0 16 -5 23 -12z"/>
<path d="M8331 4182 c-6 -2 -11 -27 -11 -59 l0 -55 30 5 c19 4 34 1 45 -9 19
-19 19 -25 1 -49 -13 -17 -17 -18 -40 -6 -29 16 -42 10 -33 -13 8 -21 47 -29
79 -17 56 21 41 116 -19 123 -20 2 -29 9 -31 25 -3 18 3 23 32 29 35 6 50 23
24 25 -38 4 -67 4 -77 1z"/>
<path d="M8508 4176 c-45 -23 -51 -153 -8 -188 30 -25 56 -23 85 7 21 20 25
34 25 82 0 35 -6 67 -15 80 -18 25 -57 34 -87 19z m58 -33 c16 -21 18 -97 4
-124 -6 -10 -20 -19 -31 -19 -26 0 -42 44 -34 95 10 62 35 82 61 48z"/>
<path d="M7200 4074 c0 -79 3 -105 12 -102 7 3 15 15 18 29 10 42 41 30 69
-27 33 -66 49 -84 72 -84 25 0 24 26 -1 33 -12 3 -27 22 -35 44 -9 21 -20 46
-26 56 -7 13 -5 24 6 40 40 57 4 117 -70 117 l-45 0 0 -106z m82 69 c23 -21
23 -38 -3 -64 -16 -16 -23 -18 -35 -8 -17 14 -18 61 -2 77 15 15 18 15 40 -5z"/>
<path d="M7697 4106 c-20 -41 -37 -80 -37 -86 0 -5 15 -10 34 -10 25 0 35 -5
39 -20 7 -29 23 -25 41 11 10 21 13 36 7 43 -6 7 -11 38 -13 71 -4 87 -25 85
-71 -9z m43 -25 c0 -32 -16 -49 -31 -34 -11 11 8 63 23 63 4 0 8 -13 8 -29z"/>
<path d="M7526 4103 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24 19z"/>
<path d="M7527 4003 c-13 -12 -7 -33 8 -33 8 0 15 9 15 20 0 20 -11 26 -23 13z"/>
<path d="M4243 3817 c-19 -11 -20 -14 -7 -31 10 -14 14 -49 14 -117 l0 -97
-39 5 c-54 7 -86 -2 -116 -35 -55 -59 -54 -158 2 -201 34 -27 92 -27 127 0
l26 20 0 -20 c0 -20 1 -20 35 -4 28 13 32 19 20 25 -13 8 -15 43 -15 238 0
244 0 243 -47 217z m-13 -277 c22 -22 28 -108 11 -153 -20 -52 -100 -45 -126
10 -46 97 46 212 115 143z"/>
<path d="M6370 3788 c-75 -51 -80 -138 -10 -196 8 -7 45 -28 82 -47 73 -38 90
-59 90 -113 0 -95 -150 -112 -204 -24 l-17 27 -1 -37 c0 -48 31 -70 105 -76
94 -8 165 47 165 128 0 56 -30 92 -111 135 -40 21 -84 49 -96 61 -50 50 -10
134 64 134 40 0 103 -32 103 -52 0 -19 14 1 15 23 2 29 -34 49 -97 56 -45 4
-58 1 -88 -19z"/>
<path d="M2654 3781 c15 -17 17 -42 14 -235 l-3 -216 35 0 c26 0 31 3 22 12
-8 8 -12 65 -12 192 l1 181 41 -45 c22 -25 100 -115 172 -200 l131 -155 2 50
c2 28 4 137 5 243 l3 192 -28 0 c-24 0 -26 -2 -17 -19 12 -23 14 -341 2 -341
-4 0 -77 81 -161 180 -145 171 -155 180 -189 180 -34 0 -34 -1 -18 -19z"/>
<path d="M4880 3775 c14 -36 13 -400 -1 -427 -11 -20 -9 -20 108 -16 130 4
174 19 229 78 38 40 52 82 53 158 1 71 -19 120 -66 163 -56 52 -90 63 -216 67
l-117 4 10 -27z m218 -20 c71 -30 107 -79 117 -161 13 -93 -33 -191 -101 -220
-19 -8 -67 -14 -109 -14 l-75 0 0 198 c0 109 3 202 7 205 12 13 124 7 161 -8z"/>
<path d="M7455 3726 c-22 -16 -15 -50 11 -54 25 -4 47 19 38 41 -8 22 -29 28
-49 13z"/>
<path d="M5652 3606 c-23 -26 -31 -41 -24 -49 6 -7 12 -53 14 -103 2 -73 7
-96 22 -113 29 -32 95 -24 110 14 4 13 2 14 -14 5 -26 -14 -36 -13 -54 6 -12
12 -16 37 -16 103 l0 87 42 -1 c24 0 43 5 45 13 3 8 -9 12 -41 12 -43 0 -45 1
-48 31 l-3 31 -33 -36z"/>
<path d="M3207 3562 c-10 -10 -17 -26 -17 -35 0 -15 3 -14 20 3 20 20 55 26
77 12 14 -9 35 -61 28 -71 -2 -5 -23 -11 -45 -14 -61 -8 -90 -31 -90 -71 0
-59 65 -87 118 -50 21 15 25 15 41 0 37 -33 76 -11 41 24 -17 17 -20 33 -20
95 0 67 -3 79 -25 100 -31 32 -103 35 -128 7z m109 -154 c-3 -17 -11 -37 -17
-45 -14 -16 -51 -17 -67 -1 -31 31 6 78 61 78 28 0 29 -2 23 -32z"/>
<path d="M3479 3551 c-36 -36 -39 -86 -8 -125 20 -25 20 -28 5 -45 -20 -22
-21 -55 -1 -71 13 -11 11 -15 -10 -32 -33 -26 -32 -61 3 -88 22 -18 37 -21 87
-18 76 5 121 33 127 80 8 51 -26 78 -100 78 -104 0 -125 51 -26 63 78 8 109
56 93 142 -3 15 2 21 19 23 12 2 22 8 22 13 0 5 -41 9 -91 9 -87 0 -92 -1
-120 -29z m115 -10 c21 -23 24 -85 6 -106 -20 -24 -66 -18 -89 11 -25 32 -27
61 -5 92 19 28 64 29 88 3z m23 -257 c30 -8 39 -41 17 -65 -13 -15 -31 -19
-76 -19 -53 0 -59 2 -70 26 -11 23 -10 29 11 45 23 19 75 24 118 13z"/>
<path d="M3805 3568 c-32 -18 -54 -48 -67 -89 -33 -110 96 -201 193 -136 112
74 71 238 -59 237 -26 0 -56 -6 -67 -12z m103 -31 c60 -64 47 -170 -23 -191
-45 -13 -105 54 -105 117 0 82 79 127 128 74z"/>
<path d="M4430 3563 c-37 -25 -48 -43 -55 -90 -17 -111 97 -196 179 -132 33
26 34 41 0 29 -64 -25 -114 1 -134 68 l-9 32 90 0 c89 0 89 0 89 26 0 15 -12
38 -29 55 -35 34 -91 39 -131 12z m92 -20 c10 -9 18 -24 18 -35 0 -15 -8 -18
-60 -18 -66 0 -77 13 -40 50 24 24 57 26 82 3z"/>
<path d="M5383 3565 c-13 -9 -23 -26 -23 -37 l0 -22 20 22 c39 42 110 14 110
-43 0 -20 -5 -25 -25 -26 -14 -1 -29 -3 -35 -4 -5 -1 -17 -3 -25 -4 -24 -2
-55 -39 -55 -66 0 -55 69 -83 118 -49 21 15 25 15 41 0 22 -20 45 -20 60 -2
10 11 8 16 -8 21 -16 5 -21 15 -22 43 -5 147 -20 175 -90 180 -28 2 -52 -3
-66 -13z m107 -152 c0 -52 -67 -87 -88 -45 -18 35 12 72 60 72 24 0 28 -4 28
-27z"/>
<path d="M5865 3555 c-23 -22 -33 -45 -20 -45 3 0 14 9 25 20 33 33 76 26 99
-18 18 -34 8 -48 -39 -55 -84 -11 -123 -66 -79 -113 26 -28 73 -32 106 -9 18
13 22 13 33 0 14 -17 38 -19 60 -5 13 8 12 12 -6 25 -19 13 -22 26 -25 99 -5
104 -20 126 -86 126 -33 0 -50 -6 -68 -25z m113 -138 c6 -28 -25 -67 -53 -67
-45 0 -55 66 -13 84 26 10 63 1 66 -17z"/>
<path d="M6696 3555 c-30 -28 -46 -64 -46 -106 0 -36 13 -64 48 -101 35 -37
98 -39 139 -4 15 13 25 28 20 32 -4 4 -13 2 -19 -4 -31 -31 -109 -5 -133 43
-25 53 -22 55 70 55 93 0 107 9 84 51 -33 63 -114 80 -163 34z m108 -17 c28
-40 21 -48 -44 -48 -49 0 -60 3 -60 17 0 49 76 72 104 31z"/>
<path d="M6962 3568 c-16 -8 -21 -16 -15 -27 12 -27 15 -150 4 -181 l-10 -30
42 0 c32 0 38 3 29 12 -14 14 -16 126 -3 161 10 26 43 43 74 38 11 -1 17 4 17
18 0 28 -33 28 -71 0 l-29 -20 0 20 c0 23 -5 25 -38 9z"/>
<path d="M7140 3571 c0 -5 3 -11 8 -13 4 -1 31 -55 59 -118 l52 -115 46 97
c25 54 45 101 45 106 0 5 9 18 20 30 l21 22 -36 0 c-31 0 -35 -3 -30 -19 6
-19 -42 -141 -55 -141 -10 0 -60 117 -60 141 0 15 -7 19 -35 19 -19 0 -35 -4
-35 -9z"/>
<path d="M7461 3571 c-9 -6 -13 -39 -15 -125 l-1 -116 40 0 c30 0 36 3 27 12
-8 8 -12 50 -12 125 0 115 -4 126 -39 104z"/>
<path d="M7631 3555 c-63 -54 -65 -151 -5 -205 51 -45 131 -39 163 12 12 19
11 20 -6 13 -65 -28 -119 -11 -149 46 -44 87 48 186 105 113 17 -22 25 -25 42
-18 l20 9 -21 28 c-30 38 -104 39 -149 2z"/>
<path d="M7908 3557 c-35 -30 -48 -58 -48 -107 0 -104 93 -162 178 -110 34 21
46 48 15 34 -10 -4 -35 -9 -55 -12 -32 -3 -42 1 -68 26 -18 18 -30 41 -30 56
0 26 0 26 85 26 85 0 85 0 85 26 0 15 -12 38 -29 55 -37 37 -93 40 -133 6z
m109 -26 c6 -14 10 -29 7 -33 -3 -4 -32 -8 -65 -8 -65 0 -74 10 -38 49 28 30
79 26 96 -8z"/>
<path d="M8170 3560 c-39 -39 -15 -108 44 -126 53 -16 73 -64 36 -84 -23 -12
-56 -5 -82 18 -16 14 -18 13 -18 -6 0 -40 83 -58 128 -26 61 43 48 91 -38 134
-40 20 -60 36 -60 48 0 40 49 55 86 27 26 -19 34 -19 34 4 0 23 -19 31 -71 31
-26 0 -46 -7 -59 -20z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
